import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import { RouterProvider } from "react-router-dom";
import { LocaleProvider } from "@hi18n/react";
import * as Sentry from "@sentry/react";
import { router } from "./router";

if (
    import.meta.env?.VITE_SENTRY_ENABLED === "true" &&
    import.meta.env?.VITE_SENTRY_ENVIRONMENT &&
    import.meta.env?.VITE_SENTRY_DSN
) {
    const integrations: (InstanceType<typeof Sentry.BrowserTracing> | InstanceType<typeof Sentry.Replay>)[] =
        [];
    integrations.push(new Sentry.BrowserTracing());
    const options: Sentry.BrowserOptions = {
        dsn: import.meta.env.VITE_SENTRY_DSN,

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: parseInt(import.meta.env.VITE_SENTRY_TRACE_SAMPLE_RATE || "1.0"),
        environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    };

    if (
        import.meta.env.VITE_SENTRY_ENABLE_REPLAY &&
        !["0", "off", "no", "false"].includes(import.meta.env.VITE_SENTRY_ENABLE_REPLAY.trim().toLowerCase())
    ) {
        integrations.push(new Sentry.Replay());

        options.replaysOnErrorSampleRate = 1.0;
        options.replaysSessionSampleRate = 1.0;
    }

    Sentry.init({ ...options, integrations });
}
const locale = import.meta.env?.VITE_LOCALE || "error";

const container = document.getElementById("root");
if (!container) {
    throw new Error("No root element found");
}

createRoot(container).render(
    <React.StrictMode>
        <LocaleProvider locales={locale}>
            <Provider store={store}>
                <RouterProvider router={router} />
            </Provider>
        </LocaleProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals((metric) => {
    if (import.meta.env?.DEV) {
        // console.info(metric);
    }
}).catch((err) => {
    if (import.meta.env?.DEV) {
        // console.error(err);
    }
});
