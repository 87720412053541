import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { NavLink, NavLinkProps, useHref, useMatch } from "react-router-dom";
import { classNames } from "../utils/classNames";
import {
    HomeIcon,
    XMarkIcon,
    Cog8ToothIcon,
    DocumentChartBarIcon,
    QuestionMarkCircleIcon,
    BanknotesIcon,
    IdentificationIcon,
    WalletIcon,
    BuildingOffice2Icon,
} from "@heroicons/react/24/outline";
import poweredSvg from "../images/corechain/corechainPowered.svg";
import Feature from "./Feature";
import useBrandedImage from "../utils/useBrandedImage";
import { useI18n } from "@hi18n/react";
import { book } from "../locale";
import { Role as UserRole } from "@corechain-technologies/types";
import { useAppSelector } from "../hooks";
import { selectAllFeatureValues } from "../store/features";
import { selectHideBatchesTable } from "../store/payer";
import { PrivacyPolicyLink, TermsOfServiceLink } from "./ToSAndPrivacyLinks";

const locale = import.meta.env?.VITE_LOCALE || "error";
const isCorechain = locale === "corechain";

interface NavTab {
    name: string;
    itemKey: string;
    href: string;
    icon: typeof HomeIcon;
    role: UserRole;
}

function getNavTabClassNames(item: NavTab, props: Parameters<NavLinkProps["className"] & object>[0]): string {
    if (item.name === "Home") {
        return classNames(
            useMatch(useHref("."))
                ? "bg-gray-100 text-branded-primary"
                : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
            "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
        );
    }

    if (item.name === "Payments") {
        return classNames(
            useMatch(useHref("./payments/*"))
                ? "bg-gray-100 text-branded-primary"
                : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
            "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
        );
    }

    return classNames(
        props.isActive && item.name !== "Home"
            ? "bg-gray-100 text-branded-primary"
            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
        "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
    );
}

interface SidebarMobileProps {
    isOpen: boolean;
    setOpen: (value: boolean) => void;
    navigation: NavTab[];
    secondaryNavigation: NavTab[];
}

const SidebarImage = () => {
    const { t } = useI18n(book);
    const { image, error } = useBrandedImage(t("app/sidebar/logo"));
    if (image) {
        return (
            <>
                <img
                    className="hidden lg:inline-block"
                    width={isCorechain ? 254 : 72}
                    src={image ?? ""}
                    alt="sidebar logo"
                />
                <img
                    className="lg:hidden -ml-3"
                    width={isCorechain ? 200 : 52}
                    src={image ?? ""}
                    alt="sidebar logo"
                />
            </>
        );
    }
    return <>{error ? error : ""}</>;
};

export const SidebarMobile: React.FC<SidebarMobileProps> = ({
    isOpen,
    setOpen,
    navigation,
    secondaryNavigation,
}) => {
    const { t } = useI18n(book);
    const isCorechainContainerStyleParent = "relative flex-1 flex flex-col max-w-xxs w-full pb-4 bg-white";
    const isCorechainContainerStyleChild = "flex-shrink-0 flex items-center";
    const isCorechainNavStyle =
        "pt-1 flex-1 flex flex-col justify-between h-full divide-y divide-gray-300 overflow-y-auto";
    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-40 eleven21:hidden" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-white bg-opacity-75" />
                </Transition.Child>

                <div className="fixed inset-0 flex z-40">
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <Dialog.Panel
                            className={
                                isCorechain
                                    ? isCorechainContainerStyleParent
                                    : "relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white"
                            }
                        >
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div
                                className={
                                    isCorechain
                                        ? isCorechainContainerStyleChild
                                        : "flex-shrink-0 flex text-branded-primary font-semibold px-4 text-lg items-center"
                                }
                            >
                                <SidebarImage />
                                {!isCorechain && (
                                    <div className="ml-3">
                                        <span>
                                            {t("app/sidebar/line1")} {t("app/sidebar/line2")}
                                        </span>
                                    </div>
                                )}
                            </div>
                            <nav
                                className={
                                    isCorechain
                                        ? isCorechainNavStyle
                                        : "mt-5 pt-1 flex-1 flex flex-col justify-between h-full divide-y divide-gray-300 overflow-y-auto"
                                }
                                aria-label="Sidebar"
                            >
                                <div className="px-2 space-y-1">
                                    {navigation.map((item) => (
                                        <NavLink
                                            key={item.name}
                                            to={item.href}
                                            className={(props) => getNavTabClassNames(item, props)}
                                            aria-current="page"
                                        >
                                            <item.icon
                                                className="mr-4 flex-shrink-0 h-6 w-6"
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </NavLink>
                                    ))}
                                </div>
                                <div className="mt-6 pt-6">
                                    <Feature flag="DEPLOY_TARGET" value="DEVELOPMENT">
                                        <div className="px-2 space-y-1">
                                            {secondaryNavigation.map((item) => (
                                                <NavLink
                                                    key={item.name}
                                                    to={item.href}
                                                    className={(props) => getNavTabClassNames(item, props)}
                                                    aria-current="page"
                                                >
                                                    {item.icon ? (
                                                        <item.icon
                                                            className="mr-4 h-6 w-6"
                                                            aria-hidden="true"
                                                        />
                                                    ) : (
                                                        <div
                                                            className="mr-4 h-6 w-6"
                                                            aria-hidden="true"
                                                        ></div>
                                                    )}
                                                    {item.name}
                                                </NavLink>
                                            ))}
                                        </div>
                                    </Feature>
                                    <div className="py-4 px-5 text-left text-gray-700 text-xs font-normal">
                                        {t("app/usage")} <TermsOfServiceLink /> and <PrivacyPolicyLink />
                                    </div>
                                </div>
                            </nav>
                        </Dialog.Panel>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

interface SidebarDesktopProps {
    navigation: NavTab[];
    secondaryNavigation: NavTab[];
}

export const SidebarDesktop: React.FC<SidebarDesktopProps> = ({ navigation, secondaryNavigation }) => {
    const { t } = useI18n(book);
    const isCorechainStyle = "bg-white rounded-lg flex flex-grow justify-center";
    const isCorechainContainerStyleParent =
        "flex flex-col flex-grow bg-white pb-4 overflow-y-auto border-r border-gray-200";
    const isCorechainContainerStyleChild = "flex items-center flex-shrink-0";
    const isCorechainNavStyle = "pt-1 flex-1 flex flex-col justify-between overflow-y-auto";

    return (
        <div className="hidden eleven21:flex eleven21:w-64 eleven21:flex-col eleven21:fixed eleven21:inset-y-0">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div
                className={
                    isCorechain
                        ? isCorechainContainerStyleParent
                        : "flex flex-col flex-grow bg-white pt-5 pb-4 overflow-y-auto border-r border-gray-200"
                }
            >
                <div
                    className={
                        isCorechain ? isCorechainContainerStyleChild : "px-2 flex items-center flex-shrink-0"
                    }
                >
                    <div
                        className={
                            isCorechain
                                ? isCorechainStyle
                                : "bg-white py-2 px-3 rounded-lg flex flex-grow gap-4 text-xl font-semibold text-branded-primary items-center"
                        }
                    >
                        <SidebarImage />
                        {!isCorechain && (
                            <div className="leading-5">
                                <div className="mb-0.5">{t("app/sidebar/line1")}</div>
                                <div>{t("app/sidebar/line2")}</div>
                            </div>
                        )}
                    </div>
                </div>
                <nav
                    className={
                        isCorechain
                            ? isCorechainNavStyle
                            : "mt-5 pt-1 flex-1 flex flex-col justify-between overflow-y-auto"
                    }
                    aria-label="Sidebar"
                >
                    <div className="px-2 space-y-1">
                        {navigation.map((item) => (
                            <NavLink
                                to={item.href}
                                key={item.name}
                                className={(props) => getNavTabClassNames(item, props)}
                                aria-current="page"
                            >
                                <item.icon className="mr-4 flex-shrink-0 h-6 w-6" aria-hidden="true" />
                                {item.name}
                            </NavLink>
                        ))}
                    </div>
                    <div className="mt-6 pt-6">
                        <div className="px-2 space-y-1">
                            <Feature flag="DEPLOY_TARGET" value="DEVELOPMENT">
                                <>
                                    {secondaryNavigation.map((item) => (
                                        <NavLink
                                            to={item.href}
                                            key={item.name}
                                            className={(props) => getNavTabClassNames(item, props)}
                                            aria-current="page"
                                        >
                                            {item.icon ? (
                                                <item.icon className="mr-4 h-6 w-6" aria-hidden="true" />
                                            ) : (
                                                <div className="mr-4 h-6 w-6" aria-hidden="true" />
                                            )}
                                            {item.name}
                                        </NavLink>
                                    ))}
                                </>
                            </Feature>
                            <div className="py-4 px-2 justify-left align-center text-left text-gray-700 text-xs font-normal">
                                {t("app/usage")} <TermsOfServiceLink /> and <PrivacyPolicyLink />
                            </div>
                        </div>

                        <div className="mt-3 pt-4 w-100 border-t border-gray-200">
                            <img src={poweredSvg} className="w-40 h-7 mx-auto" alt="powered by CoreChain" />
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
};

const Sidebar: React.FC<Omit<SidebarMobileProps, "navigation" | "secondaryNavigation">> = ({
    isOpen,
    setOpen,
}) => {
    const hideBatchesTable = useAppSelector(selectHideBatchesTable);
    const { t } = useI18n(book);
    const features = useAppSelector(selectAllFeatureValues);
    const userRole: UserRole = features.USER_ROLE === "BUYER" ? UserRole.BUYER : UserRole.SUPPLIER;
    const showDev = features.DEPLOY_TARGET === "DEVELOPMENT" ? true : false;

    const navigation: NavTab[] = [
        {
            name: t("app/nav/home"),
            href: useHref("."),
            icon: HomeIcon,
            role: UserRole.UNIVERSAL,
            itemKey: "HOME",
        },
        {
            name: t("app/nav/payments"),
            href: hideBatchesTable ? "./payments/payments-individual" : "./payments/payment-sets",
            icon: BanknotesIcon,
            role: UserRole.BUYER,
            itemKey: "PAYMENTS",
        },
        {
            name: t("app/nav/vendors"),
            href: useHref("./vendors"),
            icon: IdentificationIcon,
            role: UserRole.BUYER,
            itemKey: "VENDORS",
        },
        {
            name: t("app/nav/reports"),
            href: useHref("./reports"),
            icon: DocumentChartBarIcon,
            role: UserRole.BUYER,
            itemKey: "REPORTS",
        },
        {
            name: t("app/nav/receivables"),
            href: useHref("./receivables"),
            icon: WalletIcon,
            role: UserRole.SUPPLIER,
            itemKey: "RECEIVABLES",
        },
        {
            name: t("app/nav/payers"),
            href: useHref("./payers"),
            icon: BuildingOffice2Icon,
            role: UserRole.SUPPLIER,
            itemKey: "PAYERS",
        },
    ]
        .filter((tab) => (showDev ? true : tab.itemKey !== "VENDORS"))
        .filter((tab) => tab.role === UserRole.UNIVERSAL || tab.role === userRole);

    const secondaryNavigation: NavTab[] = [
        {
            name: t("app/nav/settings"),
            href: useHref("/login"),
            icon: Cog8ToothIcon,
            role: UserRole.UNIVERSAL,
            itemKey: "SETTINGS",
        },
        {
            name: t("app/nav/help"),
            href: useHref("/login"),
            icon: QuestionMarkCircleIcon,
            role: UserRole.UNIVERSAL,
            itemKey: "HELP",
        },
    ];

    return (
        <>
            {/* Stateful Mobile Sidebar Menu */}
            <SidebarMobile
                isOpen={isOpen}
                setOpen={setOpen}
                navigation={navigation}
                secondaryNavigation={secondaryNavigation}
            />
            {/* Static sidebar for desktop */}
            <SidebarDesktop navigation={navigation} secondaryNavigation={secondaryNavigation} />
        </>
    );
};

export default Sidebar;
